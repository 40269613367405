import { useState } from 'react';
import { useRouter } from 'next/router';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';
import { cx } from '@ui/utils';
import FooterCompact from '@components/FooterCompact/FooterCompact';
import { updateBrandNavigationTree } from '@redux/common/commonSlice';
import { useAppDispatch } from '@redux/store';
import { api } from '@api';
import { getNavigationTreeSchema } from '@api/common/browse';
import { useAuth } from '@hooks/useAuth';
import { type LandingPageDataType, useHomepageCMS } from '@hooks/useHompageCMS';
import useIsCityBeachUser from '@hooks/useIsCityBeachUser';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';
import useIsFrontlineUser from '@hooks/useIsFrontlineUser';
import useIsKidStuffUser from '@hooks/useIsKidStuffUser';
import useIsMyerUser from '@hooks/useIsMyerUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import { BUYER_BRAND_PAGE_ROUTES, ROUTES } from '@constants/route';
import Navbar from './Navbar/Navbar';
import PromoBanner from './PromoBanner/PromoBanner';
import citybeachNavtree from './citybeachNavtree';
import styles from './NavbarLayout.module.css';

interface BuyerNavbarLayoutProps {
  navbarData: z.infer<typeof getNavigationTreeSchema.shape.navigationTree>;
  landingPageData: LandingPageDataType | undefined;
  promotionText?: string;
  promotionURL?: string;
  showCompactFooter?: boolean;
  grayPageBg?: boolean;
  children: React.ReactNode;
}

export const BuyerNavbarLayout = ({
  navbarData: defaultNavbarData,
  landingPageData,
  children,
  showCompactFooter = true,
  grayPageBg = false,
}: BuyerNavbarLayoutProps) => {
  const router = useRouter();
  const dispatch = useAppDispatch();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState<boolean>(false);

  const { isMyerUser } = useIsMyerUser();
  const { isFrontlineUser } = useIsFrontlineUser();
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCityBeachUser } = useIsCityBeachUser();
  const { isKidStuffUser } = useIsKidStuffUser();
  const { isCombatSportsUser } = useIsCombatSportsUser();
  const cmsData = useHomepageCMS(landingPageData);
  const { user } = useAuth();
  const retailerId = user?.activeRetailer?.id as number;

  // this is only present for the buyer side brand page routes
  const isBuyerBrandPageRoute = BUYER_BRAND_PAGE_ROUTES.includes(router.pathname);

  const brandSlug = isBuyerBrandPageRoute
    ? (router.query.brandSlug as string)
    : isCombatSportsUser
      ? ROUTES.COMMON.COMBAT_SPORTS_SLUG
      : undefined;

  const hasBrandAccess = useQuery({
    queryKey: [api.buyers.cms.getBrandId.queryKey, { brandSlug }],
    queryFn: () => api.buyers.cms.getBrandId.query({ slug: brandSlug as string }),
    enabled: !!brandSlug,
  });

  const brandInfoQuery = useQuery({
    queryKey: [
      api.buyers.cms.getBrandPageContent.queryKey,
      retailerId,
      hasBrandAccess.data?.brandId,
    ],
    queryFn: () =>
      api.buyers.cms.getBrandPageContent.query({
        brandId: Number(hasBrandAccess.data?.brandId),
        retailerId,
      }),
    enabled: !!hasBrandAccess.data?.brandId,
  });

  useQuery({
    queryKey: [api.sellers.browse.getNavigationTree.queryKey, hasBrandAccess.data?.brandId],
    queryFn: () =>
      api.sellers.browse.getNavigationTree.query(hasBrandAccess.data?.brandId as number),
    onSuccess: data => {
      if (hasBrandAccess.data?.brandId && brandSlug && brandInfoQuery.data) {
        dispatch(
          updateBrandNavigationTree({
            brandId: hasBrandAccess.data?.brandId,
            data: isCityBeachUser ? citybeachNavtree : data.navigationTree,
            brandSlug,
            brandName: brandInfoQuery.data?.brandPageUniqueData?.name,
          })
        );
      }
    },
    enabled: !!hasBrandAccess.data?.brandId && !!brandInfoQuery.isSuccess,
  });

  // TODO: remove the text-[14px] class once design sys migration started since we want to maintain a rem based typescale based on global font-size in base.css
  return (
    <div
      className={cx(
        'relative flex min-h-screen flex-col text-[14px]',
        grayPageBg && 'bg-andisor-gray'
      )}
    >
      <header className="fixed inset-x-0 top-0 z-navbar">
        <Navbar
          brandId={hasBrandAccess?.data?.brandId}
          brandSlug={brandSlug}
          isBuyerBrandPageRoute={isBuyerBrandPageRoute}
          defaultNavbarData={defaultNavbarData}
          isMyerUser={isMyerUser}
          isFrontlineUser={isFrontlineUser}
          isCityBeachUser={isCityBeachUser}
          isKidStuffUser={isKidStuffUser}
          isMobileMenuOpen={isMobileMenuOpen}
          setMobileMenuOpen={setMobileMenuOpen}
        />
        {cmsData?.landingPageTop?.promotionText && (
          <PromoBanner
            promotionText={cmsData?.landingPageTop?.promotionText}
            promotionURL={cmsData?.landingPageTop?.promotionRedirectUrl}
            isMyerUser={isMyerUser}
            isFrontlineUser={isFrontlineUser}
            isCityBeachUser={isCityBeachUser}
            isKidStuffUser={isKidStuffUser}
            isNewBalanceUser={isNewBalanceUser}
            isMobileMenuOpen={isMobileMenuOpen}
          />
        )}
      </header>
      <main
        className={cx(
          styles.mainContainer,
          !cmsData?.landingPageTop?.promotionText && '!pt-retailer-navbar-height'
        )}
      >
        {children}
      </main>
      {showCompactFooter && (
        <div
          className={cx(
            'mt-52 bg-andisor-navy px-12 text-andisor-pink/80',
            (isMyerUser || isFrontlineUser || isCombatSportsUser) && 'bg-black text-white',
            isCityBeachUser && 'bg-[#0A2F87] text-white'
          )}
        >
          <div className="mx-auto max-w-8xl">
            <FooterCompact />
          </div>
        </div>
      )}
    </div>
  );
};

export const getBuyerNavbarLayout = (page: React.ReactNode, pageProps) => {
  return <BuyerNavbarLayout {...pageProps}>{page}</BuyerNavbarLayout>;
};
