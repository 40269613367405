import React, { useCallback, useEffect, useMemo } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import Router from 'next/router';
import { useMediaQuery } from '@mantine/hooks';
import { z } from 'zod';
import { Burger } from '@ui/core';
import { cx } from '@ui/utils';
import citybeachNavtree from '@layout/buyers/citybeachNavtree';
import { useAppSelector } from '@redux/store';
import { getNavigationTreeSchema } from '@api/common/browse';
import useIsCombatSportsUser from '@hooks/useIsCombatSportsUser';
import useIsNewBalanceUser from '@hooks/useIsNewBalanceUser';
import useIsTBNGUser from '@hooks/useIsTBNGUser';
import { getNewBalanceNavTree } from '@utils/newBalance';
import { ROUTES } from '@constants/route';
import HoverMenu from './HoverMenu/HoverMenu';
import MobileMenu from './MobileMenu/MobileMenu';
import NavActions from './NavActions/NavActions';
import SearchBoxContainer from './SearchBox/SearchBoxContainer';
import styles from './Navbar.module.css';

type NavbarProps = {
  brandId: number | undefined;
  brandSlug: string | undefined;
  isBuyerBrandPageRoute: boolean;
  defaultNavbarData: z.infer<typeof getNavigationTreeSchema.shape.navigationTree>;
  isMyerUser: boolean;
  isFrontlineUser: boolean;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isMobileMenuOpen: boolean;
  setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Navbar = ({
  brandId,
  brandSlug,
  isBuyerBrandPageRoute,
  defaultNavbarData,
  isMyerUser,
  isFrontlineUser,
  isCityBeachUser,
  isKidStuffUser,
  isMobileMenuOpen,
  setMobileMenuOpen,
}: NavbarProps) => {
  const matches = useMediaQuery('(max-width: 64em)');
  const { isNewBalanceUser } = useIsNewBalanceUser();
  const { isCombatSportsUser } = useIsCombatSportsUser();
  const { isTBNGUser } = useIsTBNGUser();

  const closeMobileMenu = useCallback(() => {
    setMobileMenuOpen(false);
  }, [setMobileMenuOpen]);

  const navigationTreeInRedux = useAppSelector(
    state =>
      state.common.navigationTrees[
        isCombatSportsUser ? ROUTES.COMMON.COMBAT_SPORTS_SLUG : brandSlug ?? ''
      ]
  );

  const navData = useMemo(() => {
    if (isCityBeachUser) return citybeachNavtree;
    if (isNewBalanceUser) return getNewBalanceNavTree();
    if (isKidStuffUser) return defaultNavbarData.filter(dt => dt.name === 'Kids & Baby');
    if (isCombatSportsUser || isBuyerBrandPageRoute) return navigationTreeInRedux?.data;
    return defaultNavbarData;
  }, [
    defaultNavbarData,
    isBuyerBrandPageRoute,
    isCityBeachUser,
    isCombatSportsUser,
    isKidStuffUser,
    isNewBalanceUser,
    navigationTreeInRedux?.data,
  ]);

  const logoURL = isCombatSportsUser
    ? `/brands/${ROUTES.COMMON.COMBAT_SPORTS_SLUG}`
    : ROUTES.COMMON.HOME;

  const { menuBasePath, searchBasePath, searchPlaceholderText, searchPlaceholderModalText } =
    useMemo(() => {
      const shouldSearchWithinBrand = isCombatSportsUser ? true : isBuyerBrandPageRoute;

      const baseBrandPath = `${ROUTES.COMMON.BRANDS}/${navigationTreeInRedux?.brandSlug}`;
      const menuBasePath = shouldSearchWithinBrand ? baseBrandPath : ROUTES.BUYERS.BROWSE;
      const searchBasePath = shouldSearchWithinBrand
        ? `${baseBrandPath}/search`
        : ROUTES.COMMON.SEARCH;
      const searchPlaceholderText = shouldSearchWithinBrand
        ? 'Search store...'
        : 'Discover +1000 products!';
      const searchPlaceholderModalText = shouldSearchWithinBrand
        ? 'Search across products and categories!'
        : 'Search across brands, products, and categories!';

      return {
        menuBasePath,
        searchBasePath,
        searchPlaceholderText,
        searchPlaceholderModalText,
      };
    }, [isBuyerBrandPageRoute, isCombatSportsUser, navigationTreeInRedux?.brandSlug]);

  useEffect(() => {
    // close mobile menu if open when window is resized to desktop
    if (!matches) closeMobileMenu();
  }, [matches, closeMobileMenu]);

  useEffect(() => {
    // close mobile menu if open on route change
    const completeHandler = () => closeMobileMenu();
    Router.events.on('routeChangeComplete', completeHandler);
    return () => {
      Router.events.off('routeChangeComplete', completeHandler);
    };
  }, [closeMobileMenu]);

  return (
    <nav
      className={cx(
        'flex flex-col items-center justify-center bg-andisor-navy px-10 py-4 font-body text-base text-white mobile:px-5',
        isMobileMenuOpen ? styles.mobileMenu : styles.desktopMenu,
        (isMyerUser || isFrontlineUser || isNewBalanceUser) && 'bg-black text-white',
        (isCityBeachUser || isKidStuffUser || isCombatSportsUser) &&
          'border-b bg-white text-andisor-navy'
      )}
    >
      <div className="flex w-full max-w-8xl items-center justify-center gap-7">
        <div className="flex flex-1 items-center gap-10 mobile:gap-5">
          <Link href={logoURL}>
            {isMyerUser ? (
              <div className="relative h-[60px] w-[140px] overflow-hidden tablet:h-[42px] tablet:w-[102px]">
                <Image src="/assets/images/myer-logo-light.svg" alt="Andisor" fill />
              </div>
            ) : isFrontlineUser ? (
              <div className="relative h-[58px] w-[62px] overflow-hidden tablet:h-[48px] tablet:w-[50px]">
                <Image src="/assets/images/frontline-logo.png" alt="Andisor" fill />
              </div>
            ) : isNewBalanceUser ? (
              <div className="relative h-[58px] w-[105px] overflow-hidden tablet:h-[48px] tablet:w-[50px]">
                <Image src="/assets/images/newbalance_logo_light.png" alt="Andisor" fill />
              </div>
            ) : isCityBeachUser ? (
              <div className="relative h-[48px] w-[120px] overflow-hidden">
                <Image src="/assets/images/citybeach_logo.png" alt="Andisor" fill />
              </div>
            ) : isKidStuffUser ? (
              <div className="relative h-[40px] w-[120px] overflow-hidden">
                <Image src="/assets/images/kidstuff_logo.png" alt="Andisor" fill />
              </div>
            ) : isCombatSportsUser ? (
              <div className="relative h-[60px] w-[107px] overflow-hidden">
                <Image src="/assets/images/adidas_logo.jpg" alt="Andisor" fill />
              </div>
            ) : (
              <div className="relative h-[63px] w-[150px] overflow-hidden tablet:h-[50px] tablet:w-[120px]">
                <Image src="/assets/logo/logo_full_light.svg" alt="Andisor" fill />
              </div>
            )}
            <span className="sr-only">Home</span>
          </Link>
          <HoverMenu
            navData={navData}
            isTBNGUser={isTBNGUser}
            isCityBeachUser={isCityBeachUser}
            isKidStuffUser={isKidStuffUser}
            isCombatSportsUser={isCombatSportsUser}
            isNewBalanceUser={isNewBalanceUser}
            basePath={menuBasePath}
          />
          <div className="flex-1 tablet:hidden">
            <SearchBoxContainer
              brandId={brandId}
              basePath={searchBasePath}
              placeholderText={searchPlaceholderText}
              placeholderModalText={searchPlaceholderModalText}
            />
          </div>
        </div>
        <div className="ml-4">
          <div className="flex items-center gap-5 text-sm tablet:hidden">
            <NavActions />
          </div>
          <div className="hidden tablet:block">
            <Burger
              opened={isMobileMenuOpen}
              onClick={() => setMobileMenuOpen(prevOpen => !prevOpen)}
              color={isCityBeachUser || isKidStuffUser || isCombatSportsUser ? 'black' : 'white'}
              aria-label="Toggle navigation"
              size="sm"
              className="flex items-center justify-end p-0"
              transitionDuration={150}
            />
          </div>
        </div>
      </div>
      {isMobileMenuOpen && (
        <MobileMenu
          navData={navData}
          isTBNGUser={isTBNGUser}
          isCityBeachUser={isCityBeachUser}
          isKidStuffUser={isKidStuffUser}
          isCombatSportsUser={isCombatSportsUser}
          isNewBalanceUser={isNewBalanceUser}
          basePath={menuBasePath}
          brandId={brandId}
          searchBasePath={searchBasePath}
          searchPlaceholderText={searchPlaceholderText}
          searchPlaceholderModalText={searchPlaceholderModalText}
        />
      )}
    </nav>
  );
};

export default Navbar;
