import { useEffect } from 'react';
import Image from 'next/image';
import { z } from 'zod';
import { cx } from '@ui/utils';
import { getNavigationTreeSchema } from '@api/common/browse';
import { ROUTES } from '@constants/route';
import LinkMenuItem from '../HoverMenu/LinkMenuItem';
import NavActions from '../NavActions/NavActions';
import SearchBoxContainer from '../SearchBox/SearchBoxContainer';
import NavAccordion from './NavAccordion';
import styles from './MobileMenu.module.css';

type MobileMenuProps = {
  navData: z.infer<typeof getNavigationTreeSchema.shape.navigationTree>;
  isTBNGUser?: boolean;
  isCityBeachUser: boolean;
  isKidStuffUser: boolean;
  isCombatSportsUser: boolean;
  isNewBalanceUser: boolean;
  basePath: string;
  searchBasePath: string;
  brandId: number | undefined;
  searchPlaceholderText: string;
  searchPlaceholderModalText: string;
};

const MobileMenu = ({
  navData,
  isTBNGUser,
  isCityBeachUser,
  isKidStuffUser,
  isCombatSportsUser,
  isNewBalanceUser,
  basePath,
  searchBasePath,
  brandId,
  searchPlaceholderText,
  searchPlaceholderModalText,
}: MobileMenuProps) => {
  useEffect(() => {
    // lock body scroll when mobile menu is mounted
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  const linkColor =
    isCityBeachUser || isKidStuffUser || isCombatSportsUser ? '!text-andisor-navy' : '';
  const highlightColor =
    isCityBeachUser || isKidStuffUser || isCombatSportsUser ? 'text-andisor-blue' : undefined;

  return (
    <div
      className={cx('flex h-full w-full animate-slide-down-fade-long flex-col', styles.mobileMenu)}
    >
      <div className="mt-6 flex flex-col items-center justify-center gap-8">
        <SearchBoxContainer
          basePath={searchBasePath}
          brandId={brandId}
          placeholderText={searchPlaceholderText}
          placeholderModalText={searchPlaceholderModalText}
        />
        <div className="flex w-full items-center gap-5 text-sm">
          <NavActions actionClassName="flex-1" />
        </div>
      </div>
      <div className="mb-2 mt-5 flex-1 overflow-y-auto overflow-x-hidden text-white">
        <NavAccordion
          navData={navData}
          linkColor={linkColor}
          highlightColor={highlightColor}
          basePath={basePath}
        />
        {!isCombatSportsUser && !isNewBalanceUser && (
          <div className="my-3">
            <LinkMenuItem
              name="Brands"
              path={ROUTES.COMMON.BRANDS}
              highlightColor={highlightColor}
            />
          </div>
        )}
        {isNewBalanceUser && (
          <LinkMenuItem
            name="Store"
            path={ROUTES.SELLERS.NEW_BALANCE_BRAND_PAGE}
            highlightColor={highlightColor}
          />
        )}
        {isTBNGUser && (
          <div className="my-3 flex items-center gap-2">
            <LinkMenuItem
              name="First Nations"
              path={ROUTES.COMMON.FIRST_NATION_BRANDS}
              highlightColor={highlightColor}
            />
            <Image
              src="/assets/images/first-nations-logo.png"
              alt="First Nation"
              width={20}
              height={20}
              className="mt-[-0.5px]"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default MobileMenu;
