import { z } from 'zod';
import { Accordion } from '@ui/core';
import { cx } from '@ui/utils';
import { getNavigationTreeSchema } from '@api/common/browse';
import NavLink from '../NavLink';

type NavAccordionProps = {
  navData: z.infer<typeof getNavigationTreeSchema.shape.navigationTree>;
  depth?: number;
  linkColor: string | undefined;
  highlightColor: string | undefined;
  basePath: string;
  parentPath?: string | null;
};

const styles = {
  label: 'py-1.5',
  itemTitle: 'text-sm',
  content: 'py-0',
  control: 'px-0 pl-0.5',
  item: 'border-none',
  chevron: 'text-white w-4 h-4',
};

const NavAccordion = ({
  navData,
  depth = 0,
  linkColor,
  highlightColor,
  basePath,
  parentPath = null,
}: NavAccordionProps) => {
  const renderNavAccordion = () => {
    return navData?.map(item => {
      const hasChildren = item?.children?.length > 0 && depth < 2;

      const currentPath = parentPath
        ? `${parentPath}/${item?.name}`.toLowerCase()
        : item?.name?.toLowerCase() ?? null;

      return (
        <Accordion.Item key={item.name} value={item.name}>
          <Accordion.Control
            classNames={{
              chevron: cx(!hasChildren && 'hidden', linkColor),
            }}
          >
            <NavLink
              className={cx('text-white', linkColor)}
              href={`${basePath}/${currentPath}`}
              highlightActiveLink={depth === 0}
              highlightColor={highlightColor}
            >
              <span className="text-base mobile:text-sm">{item.name}</span>
            </NavLink>
          </Accordion.Control>
          <Accordion.Panel>
            {hasChildren && (
              <NavAccordion
                navData={item.children}
                depth={depth + 1}
                highlightColor={highlightColor}
                linkColor={linkColor}
                basePath={basePath}
                parentPath={currentPath}
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      );
    });
  };

  return <Accordion classNames={styles}>{renderNavAccordion()}</Accordion>;
};

export default NavAccordion;
